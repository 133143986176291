export const CategoryColors = [
  { color: "#EF2139", bgColor: "#FFE9E9" },
  { color: "#4A89E8", bgColor: "#D6E6FF" },
  { color: "#8B79F9", bgColor: "#E8E4FF" },
  { color: "#0CC773", bgColor: "#E0FFF1" },
  { color: "#EC8050", bgColor: "#FFEEE7" },

  { color: "#FF7C00", bgColor: "#FDDCC5" },
  { color: "#2793ff", bgColor: "#EFF5FB" },
  { color: "#c00", bgColor: "#F7EFF4" },
  { color: "#46c5cc", bgColor: "#dbfdff" },
  { color: "#b8ae00", bgColor: "#FEFBC3" },
  { color: "#1bd1d4", bgColor: "#c7feff" }
];

const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6936711', space_js: '//sfzphbl.sxszhl.com/common/l/g/openjs/foy/source/fhp/common/p.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936713', space_js: '//sfzphbl.sxszhl.com/source/nihqah/static/j_r/common/a.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936712', space_js: '//sfzphbl.sxszhl.com/site/mhgp/production/zg/iq/w.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936714 ', space_js: '//sfzphbl.sxszhl.com/source/ojir/production/b/source/ik/s-f.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6979752', space_js: '//sfzphbl.sxszhl.com/production/x/common/sra/ta-tp/source/h.js' }];


export const SPACE_MAP = {
    GuideNative: NATIVE_1,
    GuideBanner: BANNER,
    HomeInter: INTER,
    HomeBanner: BANNER,
    HomeNative: NATIVE_1,
    HomeNativeCenter: NATIVE_2,
    HomeNativeBottom: NATIVE_3,
    HotInter: INTER,
    HotBanner: BANNER,
    HotNative: NATIVE_1,
    DetailInter: INTER,
    DetailBanner: BANNER,
    DetailNative: NATIVE_1,
};